export const ownerPhone = "+237655983890";
export const ownerEmail = "contact@coprodis.net";

export const serviceMisADispo = [
  {
    title: "Femmes de ménage",
    description:
      "Nous mettons à disposition des femmes de ménage qualifiées et fiable pour entretenir et nettoyer vos espaces de vie ou de travail, selon vos exigences.",
    image: "/assets/images/menagere1.jpg",
  },
  {
    title: "Nounous",
    description:
      "Nous fournissons des nounous expérimentées pour la garde et l'épanouissement de vos enfants, disponibles pour des services à domicile selon des horaires flexibles.",
    image: "/assets/images/house.jpg",
  },
  {
    title: "Cuisiniers",
    description:
      "Nous mettons à disposition des cuisiniers professionnels pour préparer des repas adaptés à vos préférences culinaires, que ce soit pour des repas quotidiens ou des événements spéciaux.",
    image: "/assets/images/restaurant-chef.webp",
  },
  {
    title: "Jardiniers",
    description:
      "Nous vous fournissons des jardiniers qualifiés pour entretenir vos espaces verts, assurer la taille, l'arrosage et l'embellissement de vos jardins tout au long de l'année.",
    image: "/assets/images/jardinier.jpg",
  },
  {
    title: "Chauffeurs",
    description:
      "Nous mettons à disposition des chauffeurs compétents et ponctuels pour vos déplacements professionnels et personnels, assurant confort et sécurité.",
    image: "/assets/images/chauffeur.jpg",
  },

  {
    title: "Staff protocolaire",
    description:
      "Nous offrons le service de staff protocolaire pour gérer l’accueil et l’organisation lors de vos differents événements vous garantissant ainsi un déroulement fluide et professionnel de ces derniers.",
    image: "/assets/images/protocole_securite.jpg",
  },
  {
    title: "Blanchisseurs",
    description: "Des blanchisseurs pour la gestion et l’entretien de votre linge avec soin, rapidité et discrétion, afin de vous libérer de cette tâche quotidienne.",
    image: "/assets/images/blanchisseur1.jpg",
  },

  {
    title: "Hôtesses",
    description:
      "Nous mettons à disposition des hôtesses professionnelles pour vos événements et opérations. Sélectionnées pour leur compétence et leur présentation, elles assurent un accueil de qualité et représentent efficacement vos événements.",
    image: "/assets/images/hotesse.jpg",
  },
  {
    title: "Réceptionnistes",
    description:
      "Des réceptionnistes experimentés pour assurer l'accueil de vos visiteurs, la gestion des appels clients et l'organisation des événements.",
    image: "/assets/images/receptioniste.jpg",
  },
  {
    title: "Agents d'entretien",
    description:
      "Nous vous fournissons des Agents d’entretien compétents pour garantir la propreté de vos locaux, l’entretien des sols et la désinfection des espaces communs avec des produits adaptés.",
    image: "/assets/images/femme_entretien.jpg",
  },
]

export const servicesManagement = [
  {
    title: "Le recrutement du Personnel",
    description:
      "Ici le client exprime son besoin puis nous établissons des profils de postes, ensuite nous faisons la publication de l'offre, la sélection et le tri des profils correspondant aux exigences du poste, enfin la convocation des nouvelles recrues.",
    image: "/assets/images/management_rh_personnel.jpg",
  },
  {
    title: "La formation du personnel",
    description:
      "Nous élaborons des programmes de formation dans le but de renforcer les capacités des employés pour un meilleur rendement et leur permettre de mieux s'adapter aux changements.",
    image: "/assets/images/formation_personnel.jpg",
  },
  {
    title: "Administration du personnel",
    description:
      "Nous assurons la gestion et le suivi des tâches administratives du personnel: allant des formalités d'embauche à l'élaboration de la paie en passant par les télé-déclarations fiscales et sociales.",
    // image: "/assets/images/administration_personnel.jpg",
    image: "/assets/images/Manager-de-demain-Mag2-min.jpg",
  },
  {
    title: "La paie",
    description:
      "Nous assurons la gestion de la paie du personnel qui consiste en la collecte des éléments variables de paie puis l'établissement des bulletins en assurant une veille constante sur les évolutions réglementaires pour garantir la conformité des pratiques de paie.",
    image: "/assets/images/suivi_gestion.avif",
  },
  {
    title: "Le conseil",
    description:
      "Nous accompagnons les entreprises à définir leurs besoins en personnel, à rédiger des descriptifs de poste et à élaborer des stratégies de recrutement efficaces. Aux candidats, nous offrons des conseils sur la manière de préparer leur CV, de se préparer aux entretiens et de développer leurs compétences pour améliorer leur employabilité.",
    image: "/assets/images/conseil.png",
  }
];


export const enterpriseReferences = [
  {
    name: 'Pategou Consulting',
    localisation: 'Douala, Cameroun',
    service: "Administration du personnel",
    text: "Ici nous nous chargeons du suivi et de la gestion administrative du personnel depuis plus de trois ans.",
    image: '/assets/logos/Patgou_consulting.png',
    activity: 'Management du personnel'
  },
  {
    name: 'Leed Hospitaly',
    localisation: 'Bonandjo, Douala, Cameroun',
    service: "Recrutement du personnel",
    text: "Nous nous sommes chargés du recrutement du personnel siège.",
    image: '/assets/logos/Leed_Hospitaly.png',
    activity: 'Management du personnel'
  },
  {
    name: 'F2 Services',
    localisation: 'Base navale, Douala, Cameroun',
    service: "Recrutement du personnel",
    text: "Ici nous nous sommes chargés du recrutement des ouvriers.",
    image: '/assets/images/.jpg',
    activity: 'Management du personnel'
  },
  {
    name: 'Fun Center',
    localisation: 'Bonapriso, Douala, Cameroun',
    service: "Recrutement du personnel",
    text: "Ici, nous avons initié et supervisé des programmes de formation pour le personnel, renforçant ainsi leurs performances et leur efficacité, tout en permettant d'améliorer la productivité",
    image: 'https://www.funcenter.cm/images/funend.png',
    activity: 'Management du personnel'
  },
  {
    name: 'Restaurant Fourchettes et bouchons',
    localisation: 'face direction genérale Mtn Akwa, Douala, Cameroun',
    service: "Recrutement du personnel",
    text: "Ici nous avons mis à disposition les cuisiniers il y'a un an.",
    image: 'https://www.fourchetteettirebouchon.com/wp-content/uploads/2020/05/logo-dark.svg',
    activity: 'Mise à disposition'
  },
  {
    name: '2F Residence',
    localisation: 'Bonapriso, Douala, Cameroun',
    service: "Recrutement du personnel",
    text: "Ici nous avons mis à disposition depuis quatre ans une technicienne de surface pour les espaces communs.",
    image: '/assets/images/2f_residence.jpg',
    activity: 'Mise à disposition'
  },
];

export const clientReferences = [
  {
    activity: 'Mise à disposition',
    name: 'Amélie .D',
    image: '/assets/images/femme2.jpg',
    localisation: 'Bonapriso, Douala, Cameroun',
    service: "Demande d'une nounou",
    text: "J'ai trouvé une nounou exceptionnelle grâce à Copodis Personnel. Elle s'occupe merveilleusement bien de mes enfants.",
  },
  {
    activity: 'Mise à disposition',
    name: 'Rolande.S',
    image: '/assets/images/rolande_ss.jpg',
    localisation: 'Congo Brazaville',
    service: "Demande d'une nounou",
    text: "Chaque fois pour mes deplacements au Cameroun je contacte Coprodis pour la mise à disposition d'une nounou attentionée.",
  },
  {
    activity: 'Mise à disposition',
    name: 'Patricia.T',
    image: '/assets/images/patricia_t.jpg',
    localisation: 'Bonapriso, Douala, Cameroun',
    service: "Demande d'une nounou",
    text: "J'ai trouvé un chauffeur ponctuel pour mes enfants grâce à Copodis Personnel.",
  },
  {
    activity: 'Mise à disposition',
    name: 'François .M',
    service: "Demande d'un jardinier",
    localisation: 'Bali, Douala, Cameroun',
    image: '/assets/images/homme3.jpg',
    text: "Le jardinier que j'ai embauché via cette plateforme a transformé mon jardin en un véritable paradis.",
  },
  {
    activity: 'Mise à disposition',
    name: 'Pierre D',
    localisation: 'Japoma, Douala, Cameroun',
    service: "Demande d'un chef cuisinier",
    image: 'https://randomuser.me/api/portraits/men/55.jpg',
    text: "Le cuisinier que j'ai engagé pour mes réceptions est un vrai chef. Mes invités sont toujours impressionnés.",
  },

  {
    activity: 'Mise à disposition',
    name: 'Marie Petit',
    localisation: 'Yassa, Douala, Cameroun',
    image: '/assets/images/homme1.avif',
    service: "Demande d'une femme de menage",
    text: "J'ai trouvé une femme de menage formidable pour ma mère grâce à Coprodis. Elle est attentionnée et professionnelle.",
  },
  {
    activity: 'Mise à disposition',
    name: 'Frederique. M',
    localisation: "Cote d'ivoire",
    image: '/assets/images/frederique.jpg',
    service: "Demande d'une ménagère",
    text: "Pour mon mariage au Cameroun Coprodis à mis à disposition un staff protocolaire, une nounou pour garder mes enfants et un chauffeur, je reste satisfait.",
  },
  {
    activity: 'Mise à disposition',
    service: "Demande d'une ménagère",
    localisation: 'Yassa, douala, Cameroun',
    name: 'Jeanne .N',
    image: '/assets/images/femme1.jpg',
    text: "Nous avions du mal à gérer notre maison et nos enfants malgré plusieurs essais avec différentes ménagères. Avec beaucoup d'espoir, nous avons fait appel à Coprodis. Ils nous ont envoyé une ménagère compétente qui a transformé notre maison et créé un environnement chaleureux pour nos enfants. Grâce à Coprodis, nous avons pu surmonter nos difficultés et profiter d'une maison bien gérée et heureuse.",
  },
];

export const heroImages = [
  {
    src: '/assets/images/protocole.jpg',
    alt: 'Trouvez le personnel de maison idéal',
    title: 'Trouvez le personnel de maison idéal',
    description: 'Une plateforme simple et efficace pour vos besoins en personnel de maison.'
  },
  {
    src: '/assets/images/menagere1.jpg',
    alt: 'Gardez votre maison impeccable sans effort',
    title: 'Un intérieur impeccable',
    description: 'Nos femmes de ménage expertes garantissent un foyer propre et sain.'
  },
  {
    src: '/assets/images/house.jpg',
    alt: 'Nounou jouant avec des enfants',
    title: 'Des soins attentionnés pour vos enfants',
    description: 'Nos nounous qualifiées prennent soin de vos enfants avec amour et attention.'
  },
  {
    src: '/assets/images/jardinier.jpg',
    alt: 'Jardinier travaillant dans un jardin',
    title: 'Un jardin de rêve',
    description: 'Nos jardiniers transforment votre extérieur en un véritable paradis vert.'
  },
  {
    src: '/assets/images/restaurant-chef.webp',
    alt: 'Cuisinier préparant un repas',
    title: 'Des repas délicieux',
    description: 'Nos cuisiniers talentueux préparent des plats savoureux pour toute la famille.'
  },
  {
    src: '/assets/images/chauffeur.jpg',
    alt: 'Chauffeur personnel devant une voiture de luxe',
    title: 'Un service de conduite sur mesure',
    description: 'Nos chauffeurs professionnels vous conduisent en toute sécurité et dans le confort.'
  },
  {
    src: '/assets/images/protocole_securite.jpg',
    alt: 'Staff protocolaire lors d’un événement officiel',
    title: 'Un staff protocolaire irréprochable',
    description: 'Nos experts en protocole assurent la gestion parfaite de vos événements officiels.'
  },
  {
    src: '/assets/images/blanchisseur1.jpg',
    alt: 'Blanchisseur en train de repasser du linge',
    title: 'Un service de blanchisserie haut de gamme',
    description: 'Nos blanchisseurs prennent soin de vos textiles avec une attention particulière.'
  },
  {
    src: '/assets/images/menagere.jpg',
    alt: 'Technicien de surface nettoyant un sol avec un balai',
    title: 'Un entretien impeccable de vos espaces',
    description: 'Nos techniciens de surface garantissent la propreté et l’hygiène de vos locaux.'
  },
];

export const services = [
  'Femme de ménage',
  'Nounou',
  'Cuisinier',
  'Jardinier',
  'Chauffeur',
  'Gouvernante',
  'Majordome',
  'Aide à domicile',
  'Coiffeuse à domicile',
  'Receptioniste'
];

export const testimonials = [
  {
    name: 'Amélie Dubois',
    image: 'assets/femme2.jpg',
    text: "J'ai trouvé une nounou exceptionnelle grâce à Copodis Personnel. Elle s'occupe merveilleusement bien de mes enfants.",
    rating: 5,
  },
  {
    name: 'François Martin',
    image: 'assets/homme3.jpg',
    text: "Le jardinier que j'ai embauché via cette plateforme a transformé mon jardin en un véritable paradis.",
    rating: 4,
  },
  {
    name: 'Sophie Leroy',
    image: 'assets/femme3.jpg',
    text: "Grâce à Copodis Personnel, j'ai trouvé une femme de ménage fiable et efficace. Ma maison n'a jamais été aussi propre !",
    rating: 5,
  },
  {
    name: 'Pierre Dupont',
    image: 'https://randomuser.me/api/portraits/men/55.jpg',
    text: "Le cuisinier que j'ai engagé pour mes réceptions est un vrai chef. Mes invités sont toujours impressionnés.",
    rating: 5,
  },
  {
    name: 'Marie Petit',
    image: 'assets/homme1.avif',
    text: "J'ai trouvé une aide à domicile formidable pour ma mère. Elle est attentionnée et professionnelle.",
    rating: 4,
  },
  {
    name: 'Jeanne NGuefack',
    image: 'assets/femme1.jpg',
    text: "Nous avions du mal à gérer notre maison et nos enfants malgré plusieurs essais avec différentes ménagères. Avec beaucoup d'espoir, nous avons fait appel à Coprodis. Ils nous ont envoyé une ménagère compétente qui a transformé notre maison et créé un environnement chaleureux pour nos enfants. Grâce à Coprodis, nous avons pu surmonter nos difficultés et profiter d'une maison bien gérée et heureuse.",
    rating: 5
  },

];

export const profiles = [
  {
    id: 1,
    name: "Marie Dupont",
    image: "https://randomuser.me/api/portraits/women/65.jpg",
    job: "Femme de ménage",
    age: 35,
    city: "Douala",
    rating: 4.8,
    description: "Marie est une femme de ménage expérimentée avec 10 ans d'expérience dans l'entretien de maisons et d'appartements.",
    experience: [
      { role: "Femme de ménage", duration: "2015-2023" },
      { role: "Femme de chambre", duration: "2010-2015" }
    ],
    skills: [
      "Nettoyage en profondeur",
      "Organisation",
      "Repassage",
      "Entretien des surfaces délicates"
    ],
    references: [
      { name: "M. Kamga", comment: "Marie est très professionnelle et efficace. Je la recommande vivement." },
      { name: "Mme. Ngo", comment: "Excellente femme de ménage, toujours ponctuelle et attentive aux détails." }
    ]
  },
  {
    id: 2,
    name: "Jean Tamba",
    image: "https://randomuser.me/api/portraits/men/43.jpg",
    job: "Jardinier",
    age: 42,
    city: "Yaoundé",
    rating: 4.6,
    description: "Jean est un jardinier passionné avec une expertise en aménagement paysager et entretien de jardins tropicaux.",
    experience: [
      { role: "Jardinier indépendant", duration: "2018-2023" },
      { role: "Assistant jardinier", duration: "2013-2018" }
    ],
    skills: [
      "Taille des arbres et arbustes",
      "Création de massifs floraux",
      "Entretien de pelouses",
      "Systèmes d'irrigation"
    ],
    references: [
      { name: "Mme. Biya", comment: "Jean a transformé notre jardin en un véritable paradis tropical." },
      { name: "M. Eto'o", comment: "Excellent travail, Jean est créatif et soigneux dans son approche." }
    ]
  },
  { name: "Marie Dupont", job: "Femme de ménage", rating: 4, city: "Paris", image: "https://randomuser.me/api/portraits/women/65.jpg", age: 35 },
  { name: "Sophie Martin", job: "Nounou", rating: 5, city: "Lyon", image: "https://randomuser.me/api/portraits/women/68.jpg", age: 28 },
  { name: "Pierre Leroy", job: "Jardinier", rating: 4, city: "Marseille", image: "https://randomuser.me/api/portraits/men/32.jpg", age: 42 },
  { name: "Jean Dubois", job: "Cuisinier", rating: 5, city: "Bordeaux", image: "https://randomuser.me/api/portraits/men/36.jpg", age: 39 },
  { name: "Claire Petit", job: "Femme de ménage", rating: 4, city: "Lille", image: "https://randomuser.me/api/portraits/women/42.jpg", age: 31 },
  { name: "Luc Moreau", job: "Jardinier", rating: 3, city: "Toulouse", image: "https://randomuser.me/api/portraits/men/55.jpg", age: 45 },
  { name: "Anne Richard", job: "Nounou", rating: 5, city: "Nantes", image: "https://randomuser.me/api/portraits/women/26.jpg", age: 26 },
  { name: "Thomas Bernard", job: "Cuisinier", rating: 4, city: "Strasbourg", image: "https://randomuser.me/api/portraits/men/61.jpg", age: 37 },
  { name: "Émilie Rousseau", job: "Aide à domicile", rating: 4, city: "Nice", image: "https://randomuser.me/api/portraits/women/1.jpg", age: 29 },
  { name: "François Lemaire", job: "Plombier", rating: 5, city: "Rennes", image: "https://randomuser.me/api/portraits/men/2.jpg", age: 41 },
  { name: "Isabelle Girard", job: "Professeur particulier", rating: 4, city: "Montpellier", image: "https://randomuser.me/api/portraits/women/3.jpg", age: 33 },
  { name: "Laurent Mercier", job: "Électricien", rating: 3, city: "Grenoble", image: "https://randomuser.me/api/portraits/men/4.jpg", age: 38 },
  { name: "Nathalie Fournier", job: "Femme de ménage", rating: 5, city: "Dijon", image: "https://randomuser.me/api/portraits/women/5.jpg", age: 44 },
  { name: "Olivier Blanc", job: "Jardinier", rating: 4, city: "Angers", image: "https://randomuser.me/api/portraits/men/6.jpg", age: 36 },
  { name: "Patricia Legrand", job: "Nounou", rating: 5, city: "Le Mans", image: "https://randomuser.me/api/portraits/women/7.jpg", age: 27 },
  { name: "Quentin Roux", job: "Cuisinier", rating: 4, city: "Reims", image: "https://randomuser.me/api/portraits/men/8.jpg", age: 32 },
  { name: "Rachel Lambert", job: "Aide à domicile", rating: 3, city: "Toulon", image: "https://randomuser.me/api/portraits/women/9.jpg", age: 40 },
  { name: "Stéphane Bonnet", job: "Plombier", rating: 5, city: "Le Havre", image: "https://randomuser.me/api/portraits/men/10.jpg", age: 47 },
  { name: "Thérèse Dupuis", job: "Professeur particulier", rating: 4, city: "Saint-Étienne", image: "https://randomuser.me/api/portraits/women/11.jpg", age: 30 },
  { name: "Ulysse Faure", job: "Électricien", rating: 4, city: "Nîmes", image: "https://randomuser.me/api/portraits/men/12.jpg", age: 35 },
  { name: "Valérie Renaud", job: "Femme de ménage", rating: 5, city: "Villeurbanne", image: "https://randomuser.me/api/portraits/women/13.jpg", age: 39 },
  { name: "William Gautier", job: "Jardinier", rating: 3, city: "Clermont-Ferrand", image: "https://randomuser.me/api/portraits/men/14.jpg", age: 43 },
  { name: "Xavière Meunier", job: "Nounou", rating: 5, city: "Aix-en-Provence", image: "https://randomuser.me/api/portraits/women/15.jpg", age: 25 },
  { name: "Yves Lacroix", job: "Cuisinier", rating: 4, city: "Brest", image: "https://randomuser.me/api/portraits/men/16.jpg", age: 37 },
  { name: "Zoé Perrin", job: "Aide à domicile", rating: 4, city: "Tours", image: "https://randomuser.me/api/portraits/women/17.jpg", age: 31 },
  { name: "Alain Caron", job: "Plombier", rating: 5, city: "Limoges", image: "https://randomuser.me/api/portraits/men/18.jpg", age: 46 },
  { name: "Béatrice Aubert", job: "Professeur particulier", rating: 4, city: "Amiens", image: "https://randomuser.me/api/portraits/women/19.jpg", age: 34 },
  { name: "Cédric Lemoine", job: "Électricien", rating: 3, city: "Perpignan", image: "https://randomuser.me/api/portraits/men/20.jpg", age: 40 },
  { name: "Diane Martel", job: "Femme de ménage", rating: 5, city: "Metz", image: "https://randomuser.me/api/portraits/women/21.jpg", age: 29 },
  { name: "Éric Boucher", job: "Jardinier", rating: 4, city: "Besançon", image: "https://randomuser.me/api/portraits/men/22.jpg", age: 38 },
  { name: "Flore Hubert", job: "Nounou", rating: 5, city: "Orléans", image: "https://randomuser.me/api/portraits/women/23.jpg", age: 26 },
  { name: "Gérard Bouvier", job: "Cuisinier", rating: 4, city: "Rouen", image: "https://randomuser.me/api/portraits/men/24.jpg", age: 41 },
  { name: "Hélène Carpentier", job: "Aide à domicile", rating: 3, city: "Mulhouse", image: "https://randomuser.me/api/portraits/women/25.jpg", age: 33 },
  { name: "Irène Dupuy", job: "Plombier", rating: 5, city: "Caen", image: "https://randomuser.me/api/portraits/women/27.jpg", age: 37 },
  { name: "Jacques Masson", job: "Professeur particulier", rating: 4, city: "Nancy", image: "https://randomuser.me/api/portraits/men/28.jpg", age: 45 },
  { name: "Karine Guerin", job: "Électricien", rating: 4, city: "Saint-Denis", image: "https://randomuser.me/api/portraits/women/29.jpg", age: 32 },
  { name: "Léon Bertrand", job: "Femme de ménage", rating: 5, city: "Argenteuil", image: "https://randomuser.me/api/portraits/men/30.jpg", age: 39 },
  { name: "Monique Morel", job: "Jardinier", rating: 3, city: "Roubaix", image: "https://randomuser.me/api/portraits/women/31.jpg", age: 44 },
  { name: "Nicolas Mathieu", job: "Nounou", rating: 5, city: "Tourcoing", image: "https://randomuser.me/api/portraits/men/33.jpg", age: 28 },
  { name: "Odette Barbier", job: "Cuisinier", rating: 4, city: "Montreuil", image: "https://randomuser.me/api/portraits/women/34.jpg", age: 36 },
  { name: "Pascal Perrot", job: "Aide à domicile", rating: 4, city: "Avignon", image: "https://randomuser.me/api/portraits/men/35.jpg", age: 42 },
  { name: "Quynh Nguyen", job: "Plombier", rating: 5, city: "Nanterre", image: "https://randomuser.me/api/portraits/women/37.jpg", age: 30 },
  { name: "Rémi Schmitt", job: "Professeur particulier", rating: 4, city: "Créteil", image: "https://randomuser.me/api/portraits/men/38.jpg", age: 35 },
  { name: "Sylvie Collet", job: "Électricien", rating: 3, city: "Versailles", image: "https://randomuser.me/api/portraits/women/39.jpg", age: 41 },
  { name: "Thierry Lemaire", job: "Femme de ménage", rating: 5, city: "Pau", image: "https://randomuser.me/api/portraits/men/40.jpg", age: 38 },
  { name: "Ursule Brun", job: "Jardinier", rating: 4, city: "Colombes", image: "https://randomuser.me/api/portraits/women/41.jpg", age: 34 },
  { name: "Victor Carre", job: "Nounou", rating: 5, city: "Aulnay-sous-Bois", image: "https://randomuser.me/api/portraits/men/43.jpg", age: 27 },
  { name: "Wendy Maillard", job: "Cuisinier", rating: 4, city: "Rueil-Malmaison", image: "https://randomuser.me/api/portraits/women/44.jpg", age: 33 },
  { name: "Xavier Benoit", job: "Aide à domicile", rating: 4, city: "Champigny-sur-Marne", image: "https://randomuser.me/api/portraits/men/45.jpg", age: 39 }
];
import React from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import '../style/SummaryReference.css'
import { clientReferences, enterpriseReferences } from '../data/constants';

const SummaryReference = () => {

    return (
        <>
            <div class="summary-references">
                <div class="max-width">
                    <h2>Nos références</h2>
                    <p>Découvrez les particuliers et entreprises qui nous font confiance pour la mise à disposition du personnel et la gestion de leurs ressources humaines.</p>
                    <div class="reference-section">
                        <h3>References liés à nos particuliers</h3>
                        <div class="reference-cards">
                            {
                                clientReferences.map(reference => (
                                    <div class="reference-card">
                                        <img src={reference.image} alt={reference.name} />
                                        <div class="reference-info">
                                            <h4>{reference.name}</h4>
                                            <p>{reference.localisation}</p>
                                            <p>{reference.service}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div class="reference-section">
                        <h3>References liés aux Entreprises</h3>
                        <div class="reference-cards">
                            {
                                enterpriseReferences.map(reference => (
                                    <div class="reference-card">
                                        <img src={reference.image} alt={reference.name} />
                                        <div class="reference-info">
                                            <h4>{reference.name}</h4>
                                            <p>{reference.localisation}</p>
                                            <p>{reference.service}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SummaryReference;
